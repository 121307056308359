import { getServerConfig } from '@/utils/config';
import { tryFetchToJson } from '@/utils/http-request';

import { UserDto } from './dto/user.dto';

export async function getUserById(id: string) {
  const url = `${getServerConfig().NEXT_PUBLIC_USER_API_URL}/users/${id}`;

  return tryFetchToJson<UserDto>({
    withAuthToken: true,
    fetchParams: {
      input: url,
      init: {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    },
  });
}
