import { useTranslations } from 'next-intl';

import { PrimaryButton, SecondaryButton } from './buttons';

type CookieConsentBannerProps = {
  onAccept: () => void;
  onDeny: () => void;
};

export function CookieConsentBanner({
  onAccept,
  onDeny,
}: CookieConsentBannerProps) {
  const t = useTranslations('cookie-banner');

  return (
    <div className="sticky bottom-0 left-0 z-50 flex flex-col items-center w-full px-10 py-5 bg-white lg:flex-row">
      <div className="lg:w-2/3">
        <p className="mb-2 font-bold">{t('title')}</p>
        <p>{t('description')}</p>
      </div>
      <div className="flex justify-between w-full gap-4 mt-3 lg:w-1/3 h-9 lg:mt-0">
        <SecondaryButton sm className="flex-1" onClick={onDeny}>
          {t('deny')}
        </SecondaryButton>
        <PrimaryButton sm className="flex-1 px-5 py-3" onClick={onAccept}>
          {t('allow')}
        </PrimaryButton>
      </div>
    </div>
  );
}
