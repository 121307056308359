'use client';

import { usePathname } from '@/navigation';
import { getServerConfig } from '@/utils/config';
import { DEFAULT_LOCALE, LOCALES } from '@/utils/constants';

export function HrefLinks() {
  const { HOST } = getServerConfig();
  const pathname = usePathname();
  const asPathOverride = pathname === '/' ? '' : pathname;

  return (
    <>
      {LOCALES.filter((x) => x !== DEFAULT_LOCALE).map((locale) => (
        <link
          key={locale}
          hrefLang={locale}
          rel="alternate"
          href={`${HOST}/${locale}${asPathOverride}`}
        />
      ))}

      <link
        key="default"
        hrefLang="x-default"
        rel="canonical"
        href={`${HOST}${asPathOverride}`}
      />
    </>
  );
}
