'use client';

import { sendGTMEvent } from '@next/third-parties/google';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

import { useUserLocalization } from '@/hooks/useUserLocalization';
import { isEuropeCountry } from '@/lib/geolocation';
import { StringBool } from '@/models/StringBool.enum';
import { usePathname } from '@/navigation';
import { COOKIE_CONSENT_KEY } from '@/utils/constants';
import { getCookieByKey } from '@/utils/cookies';

export const GTM_BY_PATHNAME: Record<
  string,
  { pageType: string; options?: boolean }
> = {
  '/locations/': {
    pageType: 'location',
    options: true,
  },
  '/meeting-rooms/': {
    pageType: 'meeting-room',
    options: true,
  },
  '/locations': {
    pageType: 'location-search',
  },
  '/meeting-rooms': {
    pageType: 'location-search',
  },
  '/m/': {
    pageType: 'market',
  },
  '/': {
    pageType: 'homepage',
  },
};

export function NavigationEvents() {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const cookieConsent = getCookieByKey(COOKIE_CONSENT_KEY);
  const { country } = useUserLocalization();
  const isEurope = isEuropeCountry(country);
  const canTrack = !isEurope || cookieConsent === StringBool.True;

  const pathnameKey =
    Object.keys(GTM_BY_PATHNAME).find((key) => pathname.startsWith(key)) ??
    pathname;

  const { pageType, options: hasOptionsToSend } =
    GTM_BY_PATHNAME[pathnameKey] ?? {};

  useEffect(() => {
    sendGTMEvent({ cookieConsent: canTrack });
  }, []);

  useEffect(() => {
    if (hasOptionsToSend) return;

    sendGTMEvent({
      event: 'pageview',
      url: pathname,
      pageType: pageType ?? pathname.split('/')[1],
    });
  }, [pathname, searchParams]);

  return null;
}
