'use client';

/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useEffect, useState } from 'react';

import { Claims, Scopes } from '@/lib/users/claims';
import { UserDto } from '@/lib/users/dto/user.dto';
import { getUserById } from '@/lib/users/users';

export type AuthDataContextType = {
  isAuthenticated?: boolean;
  token?: string;
  userId?: string;
  user?: UserDto;
  email?: string;
  checkAuth: () => Promise<void>;
  isLoading: boolean;
  checkClaims: (scope: Scopes, claims: Claims[]) => boolean;
};

const AuthDataContext = React.createContext<AuthDataContextType>({
  isAuthenticated: false,
  token: undefined,
  checkAuth: async () => {},
  isLoading: true,
  checkClaims: () => false,
});

const AuthProvider = ({
  children,
}: {
  locale?: string;
  children: React.ReactNode;
}) => {
  const [authData, setAuthData] = useState<AuthDataContextType>({
    isAuthenticated: false,
    token: undefined,
    isLoading: true,
    checkAuth: async () => {},
    checkClaims: () => false,
  });

  const checkAuth = async () => {
    const { checkUserToken, getUserToken } = await import(
      '@/lib/authentication/helpers'
    );
    const check = await checkUserToken();

    if (!check.isValid) {
      setAuthData({
        isAuthenticated: false,
        checkAuth,
        isLoading: false,
        checkClaims: () => false,
      });
      return;
    }

    const { token } = await getUserToken();
    const AwsAuth = (await import('aws-amplify')).Auth;
    const currentAuthenticatedUser = await AwsAuth.currentAuthenticatedUser();
    const userId = currentAuthenticatedUser.attributes['custom:user_id'];

    const user = await getUserById(userId);

    const checkClaims = (scope: Scopes, claims: Claims[]) => {
      const claimsJson = currentAuthenticatedUser.attributes[`custom:${scope}`];
      const claimsObj = claimsJson ? JSON.parse(claimsJson) : {};

      return claims.every((claim) => claimsObj[claim]);
    };

    setAuthData({
      isAuthenticated: true,
      token,
      email: currentAuthenticatedUser.attributes.email,
      userId,
      user,
      checkAuth,
      isLoading: false,
      checkClaims,
    });
  };

  useEffect(() => {
    const initAuthAsync = async () => {
      const { initAuth } = await import('@/lib/authentication/init');
      initAuth();
      await checkAuth();
    };
    initAuthAsync();
  }, []);

  return (
    <AuthDataContext.Provider value={authData}>
      {children}
    </AuthDataContext.Provider>
  );
};

const useAuthData = (): AuthDataContextType => {
  return useContext(AuthDataContext);
};

export { useAuthData };
export default AuthProvider;
