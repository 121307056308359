'use client';

import Cookies from 'js-cookie';
import { useSearchParams } from 'next/navigation';
import Script from 'next/script';
import { useEffect, useState } from 'react';

import { useUserLocalization } from '@/hooks/useUserLocalization';
import { isEuropeCountry } from '@/lib/geolocation';
import { StringBool } from '@/models/StringBool.enum';
import { COOKIE_CONSENT_KEY } from '@/utils/constants';
import {
  COOKIE_KEY_MKTO_REFERRER,
  getCookieByKey,
  removeAllCookies,
  setCookieByKey,
} from '@/utils/cookies';

import { CookieConsentBanner } from './CookieConsentBanner';

export function CookieConsent() {
  const searchParams = useSearchParams();
  const cookieConsent = getCookieByKey(COOKIE_CONSENT_KEY);
  const { country } = useUserLocalization();
  const isEurope = isEuropeCountry(country);
  const [displayCookieConsent, setDisplayCookieConsent] = useState(false);
  const showEuropeanCookieConsent = isEurope && displayCookieConsent;
  const canTrack = !isEurope || cookieConsent === StringBool.True;

  useEffect(() => {
    setDisplayCookieConsent(cookieConsent === undefined);
  }, [cookieConsent]);

  useEffect(() => {
    Cookies.set(COOKIE_KEY_MKTO_REFERRER, document.referrer);
  }, []);

  if (searchParams?.has('no-munchkin')) {
    return null;
  }

  return (
    <>
      {canTrack && (
        <>
          <Script src="/scripts/munchkin.js" strategy="lazyOnload" />
          <Script
            id="hs-script-loader"
            src="//js.hs-scripts.com/44474178.js"
            strategy="lazyOnload"
            async
            defer
          />
        </>
      )}
      {showEuropeanCookieConsent && (
        <CookieConsentBanner
          onAccept={() => {
            setDisplayCookieConsent(false);
            setCookieByKey(COOKIE_CONSENT_KEY, StringBool.True);
          }}
          onDeny={() => {
            setDisplayCookieConsent(false);
            removeAllCookies();
            setCookieByKey(COOKIE_CONSENT_KEY, StringBool.False);
          }}
        />
      )}
    </>
  );
}
